import { Home, Dashboard } from '@mui/icons-material';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import StyleOutlinedIcon from '@mui/icons-material/StyleOutlined';
import DiscountOutlinedIcon from '@mui/icons-material/DiscountOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';

export const AdminMenuItems = [
  {
    title: 'Inicio',
    category: 'Principal',
    icon: Home,
    url: '/admin-webpage',
  },
  {
    title: 'Gestión del Sitio Web',
    category: 'Principal',
    icon: Dashboard,
    url: '/admin-webpage',
  },
  {
    title: 'Zapatos',
    category: 'Productos',
    icon: ShoppingBagOutlinedIcon,
    url: '/admin-products/Zapatos',
  },
  {
    title: 'Vestidos',
    category: 'Productos',
    icon: StyleOutlinedIcon,
    url: '/admin-products/Vestidos',
  },
  {
    title: 'Moda para Hombres',
    category: 'Productos',
    icon: ShoppingBagOutlinedIcon,
    url: '/admin-products/Hombre',
  },
  {
    title: 'Moda para Mujeres',
    category: 'Productos',
    icon: ShoppingBagOutlinedIcon,
    url: '/admin-products/Mujer',
  },
  {
    title: 'Accesorios',
    category: 'Productos',
    icon: StyleOutlinedIcon,
    url: '/admin-products',
  },
/*  {
    title: 'Clientes',
    category: 'Usuarios',
    icon: GroupOutlinedIcon,
    url: '/admin-clientes',
  },*/
  {
    title: 'Administradores',
    category: 'Usuarios',
    icon: AdminPanelSettingsOutlinedIcon,
    url: '/admin-users',
  },
  {
    title: 'Cerrar Sesión',
    category: 'Salir',
    icon: LogoutIcon,
    url: '/',
  },
];
