import React from "react";
import { Card, CardContent, Typography } from "@mui/material";

const SustainabilityFeatures = () => {
  const features = [
    {
      title: "Materiales sostenibles",
      description:
        "Utilizamos materiales orgánicos y reciclados y nos preocupamos de que nuestros proveedores sigan prácticas respetuosas.",
      borderPosition: "border-b-4 border-gray-500", // Bottom border
    },
    {
      title: "Durabilidad",
      description:
        "Gracias a la calidad de nuestros tejidos nuestras prendas tienen una vida útil más larga y generan menos desperdicio textil.",
      borderPosition: "border-t-4 border-gray-500", // Top border
    },
    {
      title: "Producción local",
      description:
        "Reducimos así las emisiones de carbono asociadas al transporte y nos aseguramos que los estándares ambientales y laborales se cumplen.",
      borderPosition: "border-b-4 border-gray-500", // Bottom border
    },
  ];

  return (
    <div className="flex flex-col items-center justify-center py-10 px-6 md:px-16">
      <div className="flex flex-wrap gap-10 md:gap-0 justify-around items-center w-full">
        {features.map((feature, index) => (
          <Card
            key={index}
            className={`relative flex flex-col items-center p-6 shadow-md rounded-full ${feature.borderPosition}`}
            sx={{
              width: "320px",
              height: "320px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            }}
          >
            <CardContent className="flex flex-col items-center text-center">
              <Typography variant="h5" className="font-semibold text-gray-800">
                {feature.title}
              </Typography>
              <Typography variant="body2" className="text-gray-600 mt-2">
                {feature.description}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default SustainabilityFeatures;