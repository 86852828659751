import { publicRequest, userRequestByParams } from "./requestMethods";

export const getAllWebPage = async () => {
  try {
    const response = await publicRequest.get('/web-pages');
    return response.data;
  } catch (error) {
    console.error('Error fetching webpages:', error);
    throw error;
  }
};

export const createWebSlider = async (webSlider) => {
  try {
    const response = await publicRequest.post('/web-pages', webSlider);
    return response.data;
  } catch (error) {
    console.error('Error creating web slider:', error);
    throw error;
  }
};

export const updateWebPages = async (id, webSlider) => {
  try {
    const response = await publicRequest.put(`/web-pages/${id}`, webSlider);
    return response.data;
  } catch (error) {
    console.error('Error updating web page:', error);
    throw error;
  }
};

export const updateWebPagesStatusById = async ({ id, isActive }) => {
  try {
    const params = { isActive };
    const response = await userRequestByParams(params).put(`/web-pages/update-status/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error updating web page status:', error);
    throw error;
  }
};

export const getWebPagesByCategory = async (category) => {
  try {
    const response = await publicRequest.get(`/web-pages/category/${category}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching web pages:', error);
    throw error;
  }
};

export const deleteWebSiteSlider = async (id) => {
  try {
    const response = await publicRequest.delete(`/web-pages/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting web page:', error);
    throw error;
  }
};
