import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import React, { useState } from 'react';
import styled from 'styled-components';

const Container = styled.div``;

const ImageContainer = styled.div`
  width: 250px;
  height: 200px;
`;

const DescriptionContainer = styled.div``;

const ItemDescription = styled.div``;

const ShippingDetails = styled.div``;

const OrderDetailsContainer = styled.div``;

const QuantityForm = styled.form`
  display: flex;
  align-items: center;
  width: auto;
  margin: 0 auto;
  text-align: center;

  & #decrease {
    margin-right: -4px;
    border-radius: 8px 0 0 8px;
  }

  & #increase {
    margin-left: -4px;
    border-radius: 0 8px 8px 0;
  }

  & #input-wrap {
    margin: 0px;
    padding: 0px;
  }

  & #number {
    text-align: center;
    border: none;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    margin: 0px;
    width: 40px;
    height: 25px;
  }

  & input[type='number']::-webkit-inner-spin-button,
  & input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const QuantityButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
  margin: 0px;
  width: 40px;
  height: 25px;
  text-align: center;
  vertical-align: middle;
  padding: 11px 0;
  background: #eee;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover {
    cursor: pointer;
  }
`;

const ItemBox = ({ product }) => {
  const [quantity, setQuantity] = useState(product.quantity);
  console.log(product);
  return (
    <>
      <Container className="flex items-center gap-5 border-solid border-black border-b  p-5 w-11/12">
        <ImageContainer className="h-full">
          <img
            className="w-full h-full object-cover"
            src={product.image}
            alt="Items"
          />
        </ImageContainer>
        <DescriptionContainer className="h-full flex flex-col gap-10">
          <ItemDescription className="flex flex-col gap-1">
            <h3 id="title">{product.title}</h3>
            <p id="colection">{product.sku}</p>
            <p id="category">{product.color}</p>
            <p id="size">Lg</p>
          </ItemDescription>
          <ShippingDetails className="flex flex-col gap-1">
            <h3>Disponible</h3>
            <p id="note">
              Envío estimado en 1-2 días laborables. Disfrute de envíos y
              devoluciones gratuitos.
            </p>
          </ShippingDetails>
        </DescriptionContainer>
        <OrderDetailsContainer className="h-52 flex gap-5 items-center px-10 border-solid border-black border-l">
          <QuantityForm>
            <QuantityButton
              id="decrease"
              value="Decrease Value"
              onClick={() => (quantity > 0 ? setQuantity(quantity - 1) : '')}
            >
              <RemoveIcon />
            </QuantityButton>
            <input type="number" id="number" value={quantity} />
            <QuantityButton
              id="increase"
              value="Increase Value"
              onClick={() => setQuantity(quantity + 1)}
            >
              <AddIcon></AddIcon>
            </QuantityButton>
          </QuantityForm>
          <h3 id="total-price">${product.price}</h3>
        </OrderDetailsContainer>
      </Container>
    </>
  );
};

export default ItemBox;
