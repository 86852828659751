import { publicRequest } from "./requestMethods";

// Define the request payload type
// Note: No type annotations are needed in JS, so this is removed

// Define the response type based on what the API returns
// JS doesn't require types, so we omit the interface

export const getAllAdminUsers = async () => {
  try {
    const response = await publicRequest.get('/admin-user');
    return response.data;
  } catch (error) {
    console.error('Unexpected error:', error);
    throw error;
  }
};

export const accessAdminUser = async (accessInfo) => {
  try {
    const response = await publicRequest.post('/admin-user/login', accessInfo);
    return response.data;
  } catch (error) {
    console.error('Unexpected error:', error);
    throw error;
  }
};

export const createAdminUser = async (user) => {
  try {
    console.log(user);
    const response = await publicRequest.post('/admin-user/register', user);
    return response.data;
  } catch (error) {
    console.error('Error en la creación de usuario:', error);
    throw error;
  }
};

export const updateAdminUser = async (id, user) => {
  try {
    const response = await publicRequest.put(`/admin-user/${id}`, user);
    return response.data;
  } catch (error) {
    console.error('Error en la actualización de usuario:', error);
    throw error;
  }
};

export const deleteAdminUser = async (id) => {
  try {
    const response = await publicRequest.delete(`/admin-user/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error al eliminar usuario:', error);
    throw error;
  }
};