import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, Link, useLocation } from 'react-router-dom';
import { ShopMenu, MenuItems } from './MenuRoutes';
import { Menu, Close, ExpandMore, ExpandLess } from '@mui/icons-material';
import LogoBlack from '@/assets/img/icons/Logo-Firma.svg';
import BasicMenu from '../Button/BasicMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 0,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    color: `white`,
    background: `red`,
  },
}));

const Cart = () => {
  const quantity = useSelector((state) => state.cart.quantity);
  return (
    <StyledBadge badgeContent={quantity} key="cart-style-badge">
      <FontAwesomeIcon icon={faShoppingCart} />
    </StyledBadge>
  );
};

const MobileMenu = () => {
  const [open, setOpen] = useState(false);
  const [submenuOpen, setSubmenuOpen] = useState(null);
  const menuRef = useRef(null);
  const location = useLocation();

  // Toggle menu state
  const toggleMenu = () => {
    setOpen((prev) => !prev);
    document.body.style.overflow = open ? 'auto' : 'hidden'; // Prevent scrolling when open
  };

  // Close menu on route change
  useEffect(() => {
    setOpen(false);
    document.body.style.overflow = 'auto';
  }, [location.pathname]);

  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpen(false);
        document.body.style.overflow = 'auto';
      }
    };

    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [open]);

  // Toggle submenus
  const toggleSubmenu = (id) => {
    setSubmenuOpen((prev) => (prev === id ? null : id));
  };

  return (
    <div className="relative flex flex-row justify-between w-full z-30 bg-white py-5">
      {/* Hamburger Button */}
      <button className="text-black focus:outline-none" onClick={toggleMenu}>
        {open ? <Close fontSize="large" /> : <Menu fontSize="large" />}
      </button>

      {/* Mobile Menu Panel */}
      {open && (
        <div
          ref={menuRef}
          className="fixed top-0 left-0 w-full h-screen bg-white shadow-lg p-6 overflow-y-auto z-50"
        >
          {/* Close Button */}
          <div className="flex justify-between">
            <div className="w-1/3 flex justify-center">
              <NavLink to="/" onClick={toggleMenu}>
                <img src={LogoBlack} alt="Brand Logo" width="90" />
              </NavLink>
            </div>
            <button onClick={toggleMenu}>
              <Close fontSize="large" />
            </button>
          </div>

          {/* Main Menu Items */}
          <ul className="mt-6 space-y-4">
            {MenuItems.slice(1,-1).map((item, index) => (
              <li key={index}>
                <Link to={item.path} className="block text-lg font-semibold py-2" onClick={toggleMenu}>
                  {item.title}
                </Link>
              </li>
            ))}

            {/* Shop Dropdown */}
            <li>
              <button
                className="flex justify-between items-center w-full text-lg font-semibold py-2"
                onClick={() => toggleSubmenu(0)}
              >
                Shop {submenuOpen === 0 ? <ExpandLess /> : <ExpandMore />}
              </button>
              {submenuOpen === 0 && (
                <ul className="ml-4 space-y-2">
                  {ShopMenu.map((shopItem) => (
                    <li key={shopItem.id}>
                      <Link
                        to={shopItem.path}
                        className="block text-md text-gray-700"
                        onClick={toggleMenu} // Close menu on click
                      >
                        {shopItem.info.title}
                      </Link>

                      {/* Nested Submenu (if available) */}
                      {shopItem.submenus && (
                        <ul className="ml-4 mt-2 space-y-1">
                          {shopItem.submenus.map((subItem) => (
                            <li key={subItem.id}>
                              <Link
                                to={subItem.path}
                                className="block text-sm text-gray-600"
                                onClick={toggleMenu} // Close menu on click
                              >
                                {subItem.info.title}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </li>
          </ul>
        </div>
      )}

      {/* Logo Center */}
      <div className="w-1/3 flex justify-center">
        <NavLink to="/">
          <img src={LogoBlack} alt="Brand Logo" width="90" />
        </NavLink>
      </div>

      {/* Cart & Profile */}
      <div className="flex gap-2">
        <NavLink to="/Cart">
          <Cart />
        </NavLink>
        <BasicMenu />
      </div>
    </div>
  );
};

export default MobileMenu;