import React from 'react'
import AppointmentForm from '../components/Forms/AppoinmentForm'
import video from '../assets/img/Video/HMR 21-05-05.mp4'
import { Container } from '@mui/material'
import VerticalCarouselType2 from '../components/Carousel/VerticalCarouselType2'
import { CollectionWinter } from '@/Data/data';
import ProductsSliderType1 from '../components/Carousel/ProductsSliderType2'
import SustainabilityFeatures from '../components/SustainabilityFeatures'

const WeDressPage = () => {
  return (<>
        <div className='w-full p-10'>
          <video
            src={require('../assets/img/Video/HMR 21-05-05.mp4')}
            type="video/mp4"
            autoPlay
            controls
            preload="auto"
            loop
            muted
            width="100%"
            playsInline
          />
        </div>
      <div className='w-full p-10'>
        <SustainabilityFeatures/>
      <ProductsSliderType1/>
      </div>
    <AppointmentForm/>
  </>
  )
}

export default WeDressPage