import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import MagazinesBox from '@/components/MagazineBox/MagazineBox';
import Newsletter from '@/components/Newsletter';
import { Box } from '@mui/material';
import ProductsSlider from '../components/Carousel/ProductsSlider';
import ImageHomeCard from '../components/Cards/ImageHomeCard';
import { Link } from 'react-router-dom';

const Container = styled.section`
  position: relative;
`;

const MediaContainer = styled.div`
  height: auto;
`;

const Title = styled.h2`
  text-align: center;
  font-size: 1.2rem;
`;

const Image = styled.img`
  heigth: 100%;
  width: 100%;
  object-fit: cover;
`;

const IconCardWrapper = styled.div`
  @apply flex flex-col justify-center items-center bg-gray-100 p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 ease-in-out;
  max-width: 200px;
`;

const IconWrapper = styled.div`
  @apply mb-2;
  svg {
    font-size: 3rem;
    color: black; /* Tailwind blue-500 */
  }
`;

const TitleBox= styled.h3`
  @apply text-xl font-semibold text-gray-800;
`;

const Description = styled.p`
  @apply text-sm text-gray-400;
`;

const IconCard = ({ icon: Icon, title, description }) => {
  return (
    <IconCardWrapper className='flex flex-col items-center justify-center w-1/2'>
      <IconWrapper>
        <Icon />
      </IconWrapper>
      <TitleBox className='text-xl font-bold'>{title}</TitleBox>
      <Description className='text-gray-500 text-center'>{description}</Description>
    </IconCardWrapper>
  );
};

const HomePage = () => {
  const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch(error => {
        // console.error('Error attempting to play', error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);

  return (
    <>
      <Box>
      <Box className='p-0 md:p-10 bg-black'>
          <MediaContainer>
            <video
              src={require('../assets/img/Video/HMR 21-05-05.mp4')}
              type="video/mp4"
              ref={videoEl}
              autoPlay
              controls
              preload="auto"
              loop
              muted
              width="100%"
              playsInline
            />
          </MediaContainer>
          <Box className='flex text-center justify-center items-center'>
            <Box className='flex text-center justify-center items-center p-5 w-11/12 md:w-8/12'>
                <h3 className='font-[Ephesis] text-white text-[36px] md:text-[64px]'>
                    Where emotions meet design, and every piece tells a story.</h3>
            </Box>
          </Box>
      </Box>
      </Box>
      <Box className='flex justify-center items-center bg-white w-full'>
            <ProductsSlider/>
      </Box>
      <div className="flex justify-center items-center pb-10 relative inline-block">
        <Link to={'/ProductList/Women'}>
          <button className="button-23">
            Comprar Ahora
          </button>
          </Link>
      </div>
  
      <Box className='flex flex-wrap gap-0'>
          
      </Box>
      <Box>
        <Box className='flex flex-wrap'>
          <Box className='w-full md:w-1/2'>
            <ImageHomeCard text='Custom Made' 
            imgUrl={'https://firebasestorage.googleapis.com/v0/b/najjatharb-5a167.appspot.com/o/HomeImages%2FShopingThumbnails%2Fshop-home-1.webp?alt=media&token=d38e7725-68a6-43dd-9c70-523b0b000a78'}/>
          </Box>
          <Box className='w-full md:w-1/2'>
            <ImageHomeCard text='Accesories' 
            imgUrl={'https://firebasestorage.googleapis.com/v0/b/najjatharb-5a167.appspot.com/o/HomeImages%2FShopingThumbnails%2Fshop-home-3.webp?alt=media&token=7eb8a8de-188a-467e-9d13-a9813f14d449'}/>
          </Box>
          <Box className='w-full md:w-1/2'>
            <ImageHomeCard text='Less Waste'
            imgUrl={'https://firebasestorage.googleapis.com/v0/b/najjatharb-5a167.appspot.com/o/HomeImages%2FShopingThumbnails%2Fshop-home-2.webp?alt=media&token=1c081691-db97-4c23-8f88-a86f2f6a28ed'}/>
          </Box>
          <Box className='w-full md:w-1/2'>
            <ImageHomeCard text='We-Dress'
            imgUrl={'https://firebasestorage.googleapis.com/v0/b/najjatharb-5a167.appspot.com/o/HomeImages%2FShopingThumbnails%2Fshop-home-4.webp?alt=media&token=b13cdf01-29c5-41ed-abab-aa4ce07774c0'}/>
          </Box>
        </Box>
      </Box>
      <div className="flex justify-center items-center py-10 relative inline-block">
            <button className="text-black border-[0.5px] border-black py-5 px-8 rounded-full bg-transparent transform rotate-12 transition duration-300 ease-in-out hover:bg-white hover:text-black  hover:bg-gray-300 active:bg-gray-300">
              <span className="transform -rotate-12 inline-block border-black">Comprar Ahora</span>
            </button>
      </div>
      <Container className='bg-[#EDEFF3] flex flex-col p-0 md:p-16'>
        <Box className='flex justify-center items-start p-0 md:pl-16'>
          <h3  className='text-[48px] md:text-[64px] lg:text-[95px] text-center py-10 uppercase' >
              Spring Summer 2024 
            </h3>
        </Box>
        <Box className='p-2 md:p-20'>
          <MediaContainer>
            <video
              src={require('../assets/img/Video/HMR 21-05-05.mp4')}
              type="video/mp4"
              ref={videoEl}
              autoPlay
              controls
              preload="auto"
              loop
              muted
              width="100%"
              playsInline
            />
          </MediaContainer>
        </Box>
      </Container>
      <Container className='py-16'>
       <MagazinesBox></MagazinesBox>
      </Container>
      <Newsletter></Newsletter>
    </>
  );
};

export default HomePage;
