import {Box, Button, Container } from '@mui/material'
import VerticalNavBar from '../../components/Admin/NavBar/VerticalNavBar'
import AdminUserTable from '../../components/Admin/Tables/AdminUserTable'
import AdminUserCreateModal from '../../components/Admin/Modal/AdminUser/AddAdminUserModal'
import { useState } from 'react'
import { useSnackbar } from '../../components/Alerts/SnackBarProvider'
import { createAdminUser } from '../../services/adminUsers'
import MobileAdminNavBar from '../../components/Admin/NavBar/MobileAdminNavBar'

const AdminUserPage = () => {

  const [modalOpen, setModalOpen] = useState(false);
  const { showSnackbar } = useSnackbar();

  const handleSaveUser = async (user) => {
    // Handle saving the promotion data here
    try {
        const res = await createAdminUser(user);
        console.log(res);
        showSnackbar('Se Agragado Usuario de forma extiosa!', 'success');
    } catch (error) {
      console.log(error);
    }

    console.log('Usuario Guardadao:', user);
  };

  return (<>
   <section className='relative flex flex-col md:flex-row'>
      <div className="hidden md:block">
              <VerticalNavBar />
            </div>
            <div className="block md:hidden">
              <MobileAdminNavBar />
            </div>
    <Container>
      <Box className='flex justify-center items-center gap-5 my-5'>
        <Button onClick={() => setModalOpen(true)} variant="contained" color="primary" className='!rounded-full'>
          Agregar Usuario
        </Button>
          <AdminUserCreateModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          onCreate={handleSaveUser}
          />
        </Box>
        <AdminUserTable/>
    </Container>
    </section>
  </>
  )
}

export default AdminUserPage
