import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Divider, Skeleton } from '@mui/material';

import CardProduct from '@/components/Cards/CardProductPrev';
import Accordion from '@/components/Cart/Accordion';
import NavBar from '@/components/NavBar/NavBar';
import Newsletter from '@/components/Newsletter';
import { addProduct } from '@/redux/cartRedux';
import { getProduct, getProductByCollection, getProductGallery } from '../services/products';

const Container = styled.div``;
const ImagesContainer = styled.div``;
const ContentContainer = styled.div``;
const DetailsContainer = styled.div`
  position: sticky;
  top: 20%;
  left: 53%;
`;
const ContainerPreviewProducts = styled.section``;

const ProductDetail = () => {
  const [product, setProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [gallery, setGallery] = useState([]);
  const [loading, setLoading] = useState(true);
  const [color, setColor] = useState('');
  const [size, setSize] = useState('');
  const [collection, setCollection] = useState([]);
  const [selectedColor, setSelectedColor] = useState('');

  const location = useLocation();
  const id = location.pathname.split('/')[3];
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the route changes
  }, [id]);

  useEffect(() => {
    const fetchProduct = async () => {
      setLoading(true); // Start loading when path changes
      setProduct(null); // Clear previous product
      setGallery([]); // Clear previous gallery
      setCollection([]); // Clear previous collection

      try {
        const response = await getProduct(id);
        const data = response.data;
        setProduct(data);
        setColor(data.color?.[0] || '');
        setSize(data.size?.[0] || '');
        
        fetchGallery(data.gallery);
        if (data?.collection) {
          fetchProductByCollection(data.collection);
        }
      } catch (err) {
        console.error("Error fetching product:", err);
        setLoading(false);
      }
    };

    fetchProduct();
  }, [id]);

  const fetchGallery = async (folder) => {
    if (!folder) return;
    try {
      const res = await getProductGallery(folder);
      setGallery(res.data.files);
    } catch (error) {
      console.error("Error loading gallery:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchProductByCollection = async (collectionName) => {
    if (!collectionName) return;
    try {
      const res = await getProductByCollection(collectionName);
      setCollection(res.data);
    } catch (error) {
      console.error("Error fetching collection products:", error);
    }
  };

  const handleAddProduct = () => {
    if (!product) return;
    dispatch(addProduct({ ...product, quantity, color, size }));
  };

  const handleColorSelect = (color) => {
    setSelectedColor(color);
  };

  return (
    <>
      <Container className="flex flex-wrap bg-yellow-nj">
        <ImagesContainer className="w-full md:w-1/2 flex flex-col gap-1">
          <div className="grid grid-cols-2 gap-1">
            {loading ? (
              Array.from({ length: 4 }).map((_, index) => (
                <Skeleton key={index} variant="rectangular" width="100%" height={450} className="rounded-sm" />
              ))
            ) : gallery.length > 0 ? (
              gallery.map((item, index) => (
                <div key={index} className="relative">
                  <img
                    src={item.url}
                    alt={item.alt || 'Product Image'}
                    className="w-full h-auto rounded-md shadow-md"
                    onError={(e) => (e.target.src = '/path/to/fallback-image.jpg')}
                  />
                </div>
              ))
            ) : (
              <p>No images available</p>
            )}
          </div>
        </ImagesContainer>

        <ContentContainer className="flex flex-col gap-5 relative w-full md:w-1/2 p-5">
          <DetailsContainer className="bg-yellow-nj w-11/12 flex flex-col gap-2">
            {loading ? (
              <Skeleton variant="text" width="80%" height={30} />
            ) : (
              <div className="flex flex-wrap justify-between items-end">
                <h3 className='text-4xl font-bold'>{product?.title}</h3>
                <h3 className='text-lg font-bold font-Sans'>${product?.price}</h3>
              </div>
            )}

            {/* Colors Section */}
            <div className="space-y-4">
              <h2 className="text-lg font-semibold">Colores</h2>
                <div className="flex space-x-4">
                  {product?.color?.map((color, index) => (
                    <button
                      key={index}
                      className={`w-8 h-8 rounded-full border transition-transform duration-200 hover:scale-110 ${
                        selectedColor === color ? 'border-1 border-black ring-1 ring-offset-2 ring-black' : 'border-gray-400'
                      }`}
                      style={{
                        backgroundColor: color,
                      }}
                      title={color}
                      aria-label={`Color option: ${color}`}
                      onClick={() => handleColorSelect(color)}
                    />
                  ))}
                </div>
            </div>

            {/* Sizes Section */}
            <div className="flex flex-col space-y-2">
              <h2 className="text-lg font-semibold">Tallas</h2>
              <div className="flex space-x-3">
                {product && product.size.map((sizeOption) => (
                  <label
                    key={sizeOption}
                    className={`flex items-center justify-center w-10 h-10 border rounded-md cursor-pointer hover:bg-gray-200 ${
                      size === sizeOption ? 'border-black' : 'border-gray-400'
                    }`}
                    onClick={() => setSize(sizeOption)}
                  >
                    <input type="radio" name="size" value={sizeOption} className="hidden" />
                    <span className="text-sm font-semibold">{sizeOption}</span>
                  </label>
                ))}
              </div>
            </div>
            <div className='flex justify-center w-full items-center' >
              <button className="button-23" onClick={handleAddProduct} disabled={loading}>
                Agregar a Carrito
              </button>
            </div>
            <Accordion />
          </DetailsContainer>
        </ContentContainer>
      </Container>

      <Container className='py-20 flex flex-col gap-5'>
        <Divider variant='fullWidth'/>
        <h3 className='text-4xl font-bold text-center'>Combinalo con</h3>
      {/* Product Recommendations with Skeleton */}
      <ContainerPreviewProducts className="flex flex-wrap justify-center gap-5 py-5 w-full">
        {loading ? (
          Array.from({ length: 4 }).map((_, index) => (
            <Skeleton key={index} variant="rectangular" width="100%" height={450} className="rounded-sm w-3/4 sm:w-1/2 md:w-1/3 lg:w-1/5" />
          ))
        ) : collection.length > 0 ? (
          collection.slice(0, 4).map((item, index) => (
            <CardProduct item={item} key={index} cName="w-3/4 sm:w-1/2 md:w-1/3 lg:w-1/5" />
          ))
        ) : (
          <p className="text-gray-500 text-lg font-semibold">No products available.</p>
        )}
      </ContainerPreviewProducts>
      </Container>

      <Newsletter />
    </>
  );
};

export default ProductDetail;