import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { IconButton, Modal, Typography, Skeleton, Box, TextField, Stack, Rating } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSnackbar } from '../../Alerts/SnackBarProvider';
import { formatPrice } from '../../../utils/ConvertApp';
import { CustomToolbar } from './CustomToolBar';
import { getAllProductList,getProductByCategory } from '../../../services/products';
import { useLocation } from 'react-router-dom';


const ProductTable = ({ onSendExistingProducts }) => {
  const [products, setProducts] = useState([]);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 10 });
  const [searchText, setSearchText] = useState('');
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { showSnackbar } = useSnackbar();
  const location = useLocation();
  const id = location.pathname.split('/')[2];

  useEffect(() => {
    console.log(id);
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      setLoading(true);
      const data = await getAllProductList();
      setProducts(data);
      onSendExistingProducts(data);
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const filteredProducts = products.filter(
    (product) =>
      product.title.toLowerCase().includes(searchText.toLowerCase()) ||
      product.desc?.toLowerCase().includes(searchText.toLowerCase())
  );

  const handleDelete = async (id) => {
    try {
      //await deleteProduct(id);
      fetchProducts();
      setIsDeleteModalOpen(false);
    } catch (error) {
      console.error('Error deleting product:', error);
      showSnackbar('Error deleting product', 'error');
    }
  };

  const handleEdit = (product) => {
    setCurrentProduct(product);
    setIsEditModalOpen(true);
  };

  const handleModalClose = () => {
    setIsEditModalOpen(false);
    setIsDeleteModalOpen(false);
    setCurrentProduct(null);
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 100 },
    {
      field: 'image',
      headerName: 'Image',
      width: 150,
      renderCell: (params) => <img src={params.value} alt="Product" style={{ height: 60 }} />,
    },
    { field: 'title', headerName: 'Product', width: 200 },
    { field: 'categories', headerName: 'Category', width: 150 },
    {
      field: 'price',
      headerName: 'Price',
      width: 130,
      renderCell: (params) => (
        <Typography variant="body2">{formatPrice(params.value)}</Typography>
      ),
    },
    { field: 'inStock', headerName: 'In Stock', width: 100, type: 'boolean' },
    {
      field: 'rating',
      headerName: 'Rating',
      width: 150,
      renderCell: (params) => <Rating value={params.value || 0} precision={0.5} readOnly />,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <Stack direction="row" spacing={1}>
          <IconButton onClick={() => handleEdit(params.row)}>
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              setCurrentProduct(params.row);
              setIsDeleteModalOpen(true);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Stack>
      ),
    },
  ];

  return (
    <Box style={{ height: 'auto', width: '100%', margin: '1rem 0' }}>
      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        style={{ marginBottom: 16 }}
        value={searchText}
        onChange={handleSearch}
      />
      {loading ? (
        <Skeleton variant="rectangular" height={600} />
      ) : (
        <DataGrid
          rows={filteredProducts}
          columns={columns}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          localeText={{
            toolbarExport: 'Export',
            toolbarExportCSV: 'Download CSV',
            toolbarExportPrint: 'Print',
          }}
          slots={{ toolbar: CustomToolbar }}
          pageSizeOptions={[5, 10, 20]}
          pagination
        />
      )}
    </Box>
  );
};

export default ProductTable;
