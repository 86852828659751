import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import * as React from 'react';

export default function ControlledAccordions() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
          <Typography sx={{ width: '45%', flexShrink: 0 }}>Términos Generales</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Estos términos establecen las condiciones generales de uso de nuestros servicios y productos. Al utilizar nuestra plataforma, aceptas cumplir con todas las normativas establecidas.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
          <Typography sx={{ width: '33%', flexShrink: 0 }}>Términos de Envío</Typography>
          <Typography sx={{ color: 'text.secondary' }}>Envíos en 3-4 días hábiles</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Realizamos envíos a nivel nacional en un período de 3 a 4 días hábiles. En caso de retrasos por causas externas, nos comprometemos a informar a los clientes de inmediato.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3bh-content" id="panel3bh-header">
          <Typography sx={{ width: '33%', flexShrink: 0 }}>Cancelación de Pedido</Typography>
          <Typography sx={{ color: 'text.secondary' }}>Debe realizarse antes del envío</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Puedes cancelar tu pedido sin costo adicional siempre que la solicitud se realice antes de que el producto haya sido enviado. Contáctanos lo antes posible para gestionar la cancelación.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4bh-content" id="panel4bh-header">
          <Typography sx={{ width: '33%', flexShrink: 0 }}>Términos de Devoluciones</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Si el producto no cumple con tus expectativas, puedes solicitar una devolución dentro de los primeros 15 días hábiles posteriores a la recepción del pedido. El producto debe estar en su estado original.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
