import React, { useEffect, useState } from 'react';
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputAdornment,
} from '@mui/material';
import { Phone, Email, CalendarToday, AccessTime, AccountCircle } from '@mui/icons-material'; // Add the necessary icons
import { getWebPagesByCategory } from '../services/webPage';

const CustomMadePage = () => {
  const category = 'CustomMade';
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    date: '',
    time: '',
    dressType: '',
    location: '',
    appointmentType: 'inBoutique', // Default to "In Boutique"
    notes: '',
  });
  const [bannerUrl, setBannerUrl] = useState();

  useEffect(() => {
    handleImageUrl();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleImageUrl = async () => {
    try {
      const res = await getWebPagesByCategory(category);

      if (!res || res.length === 0) {
        console.warn('No webpages found for the category.');
        return;
      }

      const firstActiveItem = res.find((item) => item.isActive);

      if (firstActiveItem) {
        setBannerUrl(firstActiveItem.imgUrl);
      } else {
        console.warn('No active item found.');
      }
    } catch (error) {
      console.error('Error fetching webpages:', error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission, e.g., send data to an API
    console.log('Form Data Submitted:', formData);
    alert('Appointment request submitted successfully!');
  };

  return (
    <div>
      <Box className="h-[400px] w-full flex justify-center text-center ">
        <img className="w-full h-full object-cover" src={bannerUrl} alt={category} />
      </Box>
      <Container maxWidth="md" className="p-10">
        
        <Box component="form" onSubmit={handleSubmit} className="text-center w-full p-20 mx-auto bg-[#f6f5f3]">
        <Typography 
          variant="h4" 
          className="text-center mb-6 uppercase"
          sx={{
            fontFamily: '"Didot", sans-serif', // Ensure fallback in case the font is unavailable
            fontWeight: 600, // Adjust weight for better visibility
            marginBottom: 3, // Equivalent to `mb-6` in Tailwind
            letterSpacing: "0.5px", // Slight spacing for elegance
          }}>
          Agenda una Cita
        </Typography>
          <Box className="flex flex-wrap justify-between">
            {/* Name */}
            <TextField
              label="Nombre"
              name="name"
              value={formData.name}
              onChange={handleChange}
              margin="normal"
              required
              variant="standard"
              className="w-5/12"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
              sx={{
                "& .MuiInput-underline:before": {
                  borderBottomColor: 'gray', // Default color
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: 'black', // Color when selected (focused)
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: 'black', // Color of the label when the input is focused
                },
              }}
            />

            {/* Email */}
            <TextField
              label="Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              margin="normal"
              required
              variant="standard"
              className="w-5/12"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Email />
                  </InputAdornment>
                ),
              }}
              sx={{
                "& .MuiInput-underline:before": {
                  borderBottomColor: 'gray', // Default color
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: 'black', // Color when selected (focused)
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: 'black', // Color of the label when the input is focused
                },
              }}
            />
          </Box>
          <Box className="flex flex-wrap justify-between">
            {/* Phone */}
            <TextField
              label="Phone Number"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              margin="normal"
              required
              variant="standard"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Phone />
                  </InputAdornment>
                ),
              }}
              sx={{
                "& .MuiInput-underline:before": {
                  borderBottomColor: 'gray', // Default color
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: 'black', // Color when selected (focused)
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: 'black', // Color of the label when the input is focused
                },
              }}
            />

            <Box className="flex gap-5">
              {/* Preferred Date */}
              <TextField
                label="Preferred Date"
                name="date"
                type="date"
                value={formData.date}
                onChange={handleChange}
                margin="normal"
                InputLabelProps={{ shrink: true }}
                required
                variant="standard"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CalendarToday />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottomColor: 'gray', // Default color
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: 'black', // Color when selected (focused)
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: 'black', // Color of the label when the input is focused
                  },
                }}
              />

              {/* Preferred Time */}
              <TextField
                label="Preferred Time"
                name="time"
                type="time"
                value={formData.time}
                onChange={handleChange}
                margin="normal"
                InputLabelProps={{ shrink: true }}
                required
                variant="standard"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccessTime />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottomColor: 'gray', // Default color
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: 'black', // Color when selected (focused)
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: 'black', // Color of the label when the input is focused
                  },
                }}
              />
            </Box>
          </Box>

          <Box className="flex gap-5 items-center justify-between">
            {/* Dress Type */}
            <FormControl margin="normal" required variant="standard" className="w-56 my-0">
              <InputLabel>Dress Type</InputLabel>
              <Select
                name="dressType"
                value={formData.dressType}
                onChange={handleChange}
                label="Dress Type"
              >
                <MenuItem value="wedding">Wedding Dress</MenuItem>
                <MenuItem value="evening">Evening Gown</MenuItem>
                <MenuItem value="cocktail">Cocktail Dress</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Select>
            </FormControl>

          </Box>

          {/* Additional Notes */}
          <TextField
            fullWidth
            label="Additional Notes"
            name="notes"
            value={formData.notes}
            onChange={handleChange}
            margin="normal"
            multiline
            rows={4}
          />

          <Box className="flex justify-center w-full">
            {/* Submit Button */}
            <button type="submit" className="mt-6 button-23">
              Agendar Cita
            </button>
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default CustomMadePage;