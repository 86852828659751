import React from 'react';

import Slide from '@/components/Carousel/Slide';
import NavBar from '@/components/NavBar/NavBar';

const CollectionPage = () => (
  <>
    <Slide />
  </>
);

export default CollectionPage;
