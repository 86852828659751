import { publicRequest,userRequestByParams } from './requestMethods';


export const getAllProductList = async () => {
  try {
    const response = await publicRequest.get('/products');
    return response.data;
  } catch (error) {
    console.error('Error fetching companies:', error);
    throw error;
  }
};


async function getProductList(cat) {
  return await publicRequest.get(cat ? `/products?category=${cat}` : '/products');
}

async function getProduct(id) {
  return await publicRequest.get(`/products/${id}`);
}

async function getProductGallery(folder) {
  const params = {
    folder:folder
  }
  return await userRequestByParams(params).get(`/media/files-url-by-folder`);
}

async function getProductByCategory(category) {
  return await publicRequest.get(`products/by-category?categories=${category}`);
}

async function getProductByCollection(collection) {
  const params = {
    collection:collection
  }
  return await userRequestByParams(params).get(`/products/by-collection`);
}

async function getProductByProductName(productName) {
  const params = {
    productName:productName
  }
  return await userRequestByParams(params).get(`/products/by-name`);
}

async function getColors(cat) {
  return await publicRequest.get(cat ? `products/colors?categories=${cat}` : '/products',
  );
}

async function getSizes(cat) {
  return await publicRequest.get(cat ? `/products/sizes?categories=${cat}` : '/products',
  );
}

export { getProductList, getProduct,getProductByCategory, getColors, getSizes, getProductGallery, getProductByCollection,getProductByProductName };