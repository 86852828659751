import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Typography, CircularProgress, Box } from '@mui/material';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';

import { getProductByCategory } from '@/services/products';

import CardProduct from './Cards/CardProd';

const Container = styled.div``;

const Products = ({ cat, useFilters, sort }) => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getProducts = async () => {
      try {
        const response = await getProductByCategory(cat);
        const data = response.data;
        setProducts(data);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };

    if (cat) {
      getProducts();
    }
  }, [cat]);

  useEffect(() => {
    if (cat && products.length > 0) {
      setFilteredProducts(
        products.filter(item =>
          Object.entries(useFilters).every(([key, value]) =>
            item[key].includes(value),
          ),
        ),
      );
    } else {
      setFilteredProducts(products);
    }
  }, [products, cat, useFilters]);

  useEffect(() => {
    if (sort === 'newest') {
      setFilteredProducts(prev =>
        [...prev].sort((a, b) => a.createdAt - b.createdAt),
      );
    } else if (sort === 'asc') {
      setFilteredProducts(prev => [...prev].sort((a, b) => a.price - b.price));
    } else {
      setFilteredProducts(prev => [...prev].sort((a, b) => b.price - a.price));
    }
  }, [sort]);

  return (
    <Container>
      <div className="flex flex-wrap justify-center gap-5 py-5 md:py-10">
        {loading ? (
          <Box className="flex justify-center items-center">
            <CircularProgress />
          </Box>
        ) : filteredProducts.length === 0 ? (
          <Box className="flex flex-col justify-center items-center space-y-4">
            <SentimentDissatisfiedIcon className="text-4xl text-gray-500" />
            <Typography variant="h6" color="textSecondary">
              Por el momento no hay productos de esta categoría
            </Typography>
          </Box>
        ) : (
          filteredProducts.map((item) => (
            <CardProduct
              key={item.id}
              name={item.title}
              imgSrc={item.image}
              price={item.price}
              itemId={item.id}
              cName="w-3/4 sm:w-2/5 md:w-[30%]"
            />
          ))
        )}
      </div>
    </Container>
  );
};

export default Products;
