import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { A11y, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Box, Card, CardContent, CardMedia, Typography, IconButton } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';

const CarouselBox = styled(Swiper)`
  width: 100%;
  height: 350px;
  position: relative;
  overflow: hidden;

  & .swiper-pagination {
    bottom: 0px;
  }

  & .swiper-pagination-bullet-active {
    background-color: #ff5722;
  }

  @media (max-width: 768px) {
    height: auto;
  }
`;

const Slider = styled.div`
  position: relative;
  height: 650px;
  width: 100vw;
  overflow: hidden;

  @media (max-width: 600px) {
    height: 100%;
  }
`;

const CustomArrow = styled(IconButton)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  transition: 0.3s;

  &:hover {
    background: rgba(255, 255, 255, 1);
  }
`;

const VideoSlider = () => {
  const [videoList, setVideoList] = useState([]);
  const [hoveredVideo, setHoveredVideo] = useState(null);
  const swiperRef = useRef(null);

  useEffect(() => {
    setVideoList([
      { videoUrl: 'yycVNcishrE', title: 'Fashion Week' },
      { videoUrl: 'yYI9MiM1dtk', title: 'Summer' },
      { videoUrl: 'GFAVL4OLjzY', title: 'Style' },
      { videoUrl: 'fJ9rUzIMcZQ', title: 'Bohemian Rhapsody' }
    ]);
  }, []);

  return (
    <Box className="flex flex-row w-full justify-center items-center relative gap-5 my-10 w-10/12 md:w-full">
      {/* Custom Arrows */}
      <CustomArrow
        className="prev"
        sx={{ left: 10 }}
        onClick={() => swiperRef.current?.swiper?.slidePrev()}
      >
        <ArrowBackIos />
      </CustomArrow>
      <CarouselBox
        modules={[Navigation, Pagination, A11y]}
        loop={true}
        pagination={{ dynamicBullets: true }}
        slidesPerView={1}
        initialSlide={3}
        spaceBetween={10}
        centeredSlides={true}
        ref={swiperRef}
        breakpoints={{
          320: { slidesPerView: 1, spaceBetween: 0 },
          480: { slidesPerView: 1, spaceBetween: 0 },
          768: { slidesPerView: 2, spaceBetween: 1 },
          1024: { slidesPerView: 3, spaceBetween: 30 },
          1280: { slidesPerView: 4, spaceBetween: 40 },
        }}
      >
        {videoList.length > 0 ? (
          videoList.map((item, index) => (
            <SwiperSlide key={`slide-${index}`}>
              <Slider>
                <Card
                  sx={{cursor: 'pointer' }}
                  className='w-9/12 md:w-72'
                  onMouseEnter={() => setHoveredVideo(item.videoUrl)}
                  onMouseLeave={() => setHoveredVideo(null)}
                  onClick={() => window.open(`https://www.youtube.com/watch?v=${item.videoUrl}`, '_blank')}
                >
                  {hoveredVideo === item.videoUrl ? (
                    <CardContent>
                      <iframe
                        width="100%"
                        height="250"
                        src={`https://www.youtube.com/embed/${item.videoUrl}?autoplay=1&mute=1`}
                        title={item.title}
                        frameBorder="0"
                        allow="autoplay; encrypted-media"
                        allowFullScreen
                      ></iframe>
                    </CardContent>
                  ) : (
                    <CardMedia
                      component="img"
                      height="140"
                      image={`https://img.youtube.com/vi/${item.videoUrl}/0.jpg`}
                      alt={item.title}
                    />
                  )}
                  <CardContent>
                    <Typography variant="h6" component="div" noWrap>
                      {item.title}
                    </Typography>
                  </CardContent>
                </Card>
              </Slider>
            </SwiperSlide>
          ))
        ) : (
          <div className="text-center text-gray-500 py-5">No videos available</div>
        )}
      </CarouselBox>
      <CustomArrow
        className="next"
        sx={{ right: 10 }}
        onClick={() => swiperRef.current?.swiper?.slideNext()}
      >
        <ArrowForwardIos />
      </CustomArrow>
    </Box>
  );
};

export default VideoSlider;
