import React from 'react';
import styled from 'styled-components';

import MagazinesBox from '@/components/MagazineBox/MagazineBox';
import NavBar from '@/components/NavBar/NavBar';
import Newsletter from '@/components/Newsletter';

const Container = styled.div`
  position: relative;
  background-color: #000000;

  & * {
    color: white;
  }

  & .media-article {
    height: 130vh;
    width: 70vw;
    max-width: 950px;
    overflow: hidden;
  }

  & .artist-logo-nx {
    position: sticky;
    top: 60%;
  }

  @media (max-width: 600px) {
    & .media-article {
      height: fit-content;
      width: 100vw;
    }
  }
`;

const Title = styled.h2`
  text-align: center;
  font-size: 1.2rem;
`;

const SubTitle = styled.h3`
    text-align:center;
    font-size:1.2rem;
    cursor:pointer;

    &:hover{
    text-decoration: underline;
    }

}

`;

const ImageContainer = styled.div`
  max-width: 950px;

  @media (max-width: 600px) {
    width: 100vw;
  }
`;

const Image = styled.img`
  object-fit: cover;
`;

const FirmContainer = styled.div`
  & h2 {
    font-size: 3rem;
  }

  & h3 {
    font-size: 2rem;
    line-height: 1rem;
    padding-left: 10rem;
    cursor: default;
  }

  & h3:hover {
    text-decoration: none;
  }
`;

const Slide = styled.div``;

const ContentConatiner = styled.div``;
const Description = styled.p``;
const Button = styled.button`
  font-size: 1rem;
  font-weigth: bold;
  text-decoration: underline;
  text-underline-offset: 0.25rem;
`;

const Index = () => (
  <>
    <Container
      id="highligth-stand-1"
      className="flex flex-col justify-center items-center gap-5 py-10"
    >
      <div className="title-article flex flex-col justify-center gap-2">
        <Title>Primavara Coleccion </Title>
        <SubTitle>Par ella </SubTitle>
      </div>
      <ImageContainer className="media-article md:w-3/4">
        <Image
          src={require('../assets/img/Summer2022/NJP/NajjatHarb_by_XavierBergman_29.jpg')}
          alt="img"
          className="w-full h-full"
        />
      </ImageContainer>
      <FirmContainer className="artist-logo-nx">
        <Title>Najjat Harb</Title>
        <SubTitle>& Xavier Berg</SubTitle>
      </FirmContainer>
    </Container>

    <Slide className="flex flex-wrap gap-5 md:gap-0 py-32">
      <ImageContainer className="w-full md:w-2/3 flex">
        <Image
          src={require('../assets/img/Summer2022/NJP/NajjatHarb_by_XavierBergman_29.jpg')}
          alt="img"
          className="w-1/2"
        />
        <Image
          src={require('../assets/img/Summer2022/NJP/NajjatHarb_by_XavierBergman_24.jpg')}
          alt="img"
          className="w-1/2"
        />
      </ImageContainer>
      <ContentConatiner className="px-10 w-full md:w-1/3 flex flex-col justify-center items-start gap-5">
        <FirmContainer className="artist-logo-nx">
          <Title>Najjat Harb</Title>
          <SubTitle>& Xavier Berg</SubTitle>
        </FirmContainer>
        <Description className="text-justify">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut pretium
          pretium tempor. Ut eget imperdiet neque. In volutpat ante semper diam
          molestie, et aliquam erat laoreet. Sed sit amet arcu aliquet, molestie
          justo at, auctor nunc. Phasellus ligula ipsum, volutpat eget semper
          id, viverra eget nibh. Suspendisse luctus mattis cursus. Nam
          consectetur ante at nisl hendrerit gravida. Donec vehicula rhoncus
          matti
        </Description>
        <Button>Descubrir</Button>
      </ContentConatiner>
    </Slide>
    <Slide className="flex flex-wrap-reverse gap-5 md:gap-0 py-10 md:py-0">
      <ContentConatiner className="px-10 w-full md:w-1/3 flex flex-col justify-center items-start gap-5">
        <FirmContainer className="artist-logo-nx">
          <Title>Najjat Harb</Title>
          <SubTitle>& Xavier Berg</SubTitle>
        </FirmContainer>
        <Description className="text-justify">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut pretium
          pretium tempor. Ut eget imperdiet neque. In volutpat ante semper diam
          molestie, et aliquam erat laoreet. Sed sit amet arcu aliquet, molestie
          justo at, auctor nunc. Phasellus ligula ipsum, volutpat eget semper
          id, viverra eget nibh. Suspendisse luctus mattis cursus. Nam
          consectetur ante at nisl hendrerit gravida. Donec vehicula rhoncus
          matti
        </Description>
        <Button>Descubrir</Button>
      </ContentConatiner>
      <ImageContainer className="w-full md:w-2/3">
        <Image
          src={require('../assets/img/Summer2022/NJP/NajjatHarb_by_XavierBergman_50.jpg')}
          alt="img"
          className="w-full"
        />
      </ImageContainer>
    </Slide>
    <Slide className="flex flex-wrap gap-5 md:gap-0 ">
      <ImageContainer className="w-full md:w-2/3">
        <Image
          src={require('../assets/img/Summer2022/NJP/NajjatHarb_by_XavierBergman_55.jpg')}
          alt="img"
          className="w-full"
        />
      </ImageContainer>
      <ContentConatiner className="px-10 w-full md:w-1/3 flex flex-col justify-center items-start gap-5">
        <FirmContainer className="artist-logo-nx">
          <Title>Najjat Harb</Title>
          <SubTitle>& Xavier Berg</SubTitle>
        </FirmContainer>
        <Description className="text-justify">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut pretium
          pretium tempor. Ut eget imperdiet neque. In volutpat ante semper diam
          molestie, et aliquam erat laoreet. Sed sit amet arcu aliquet, molestie
          justo at, auctor nunc. Phasellus ligula ipsum, volutpat eget semper
          id, viverra eget nibh. Suspendisse luctus mattis cursus. Nam
          consectetur ante at nisl hendrerit gravida. Donec vehicula rhoncus
          matti
        </Description>
        <Button>Descubrir</Button>
      </ContentConatiner>
    </Slide>
    <Slide className="flex flex-wrap-reverse gap-5 md:gap-0 py-32">
      <ContentConatiner className="px-10 w-full md:w-1/3 flex flex-col justify-center items-start gap-5">
        <FirmContainer className="artist-logo-nx">
          <Title>Najjat Harb</Title>
          <SubTitle>& Xavier Berg</SubTitle>
        </FirmContainer>
        <Description className="text-justify">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut pretium
          pretium tempor. Ut eget imperdiet neque. In volutpat ante semper diam
          molestie, et aliquam erat laoreet. Sed sit amet arcu aliquet, molestie
          justo at, auctor nunc. Phasellus ligula ipsum, volutpat eget semper
          id, viverra eget nibh. Suspendisse luctus mattis cursus. Nam
          consectetur ante at nisl hendrerit gravida. Donec vehicula rhoncus
          matti
        </Description>
        <Button>Descubrir</Button>
      </ContentConatiner>
      <ImageContainer className="w-full md:w-2/3 flex">
        <Image
          src={require('../assets/img/Summer2022/NJP/NajjatHarb_by_XavierBergman_20.jpg')}
          alt="img"
          className="w-1/2"
        />
        <Image
          src={require('../assets/img/Summer2022/NJP/NajjatHarb_by_XavierBergman_22.jpg')}
          alt="img"
          className="w-1/2"
        />
      </ImageContainer>
    </Slide>
    <MagazinesBox />
    <Newsletter />
  </>
);

export default Index;
