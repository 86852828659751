import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import ControlledAccordions from '@/components/Cart/Accordion';
import ItemBox from '@/components/Cart/ItemBox';
import NavBar from '@/components/NavBar/NavBar';
import PaymentCard from '@/components/Payment/PaymentCard';

const Container = styled.div``;

const CartItemsContainer = styled.div``;

const CheckoutContainer = styled.div`
  background-color: rgb(246, 245, 243);
`;

const OrderDetails = styled.div``;

const ConditionsContainer = styled.div``;

const Cart = () => {
  const cart = useSelector(state => state.cart);

  console.log(cart);

  return (
    <Container className="flex flex-wrap">
      <CartItemsContainer className="w-4/6 flex flex-col items-center justify-center">
        <h1 className="border-b border-solid border-black w-11/12">
          Detalle de Carrito
        </h1>

        {cart?.products?.length > 0 && cart.products.map((item, index) => (
          <ItemBox key={index} product={item} />
        ))}
      </CartItemsContainer>

      <CheckoutContainer className="flex flex-col gap-5 w-2/6 p-5">
        <h1 className="text-center">Resumen de Pedido</h1>
        <OrderDetails className="bg-white text-center">
          <p id="order-id">#Pedido: 364787848</p>
          <div>
            <h1>Total:</h1>
            <h2>${cart?.total ?? 0}</h2>
          </div>
        </OrderDetails>
        {/*<button className="button-23">Continuar</button>*/}
        <ConditionsContainer>
          <ControlledAccordions />
        </ConditionsContainer>
      </CheckoutContainer>
    </Container>
  );
};

export default Cart;