import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import React, { useState } from 'react';
import styled from 'styled-components';
import {Link,useNavigate } from 'react-router-dom';
import LogoFirma from '@/assets/img/icons/Logo-Firma.svg';

const Container = styled.div`
  position: relative;
  background: white;
  cursor: pointer;
  overflow:hidden
  width:300px;
  
`;

const ImageContainer = styled.div`
  width: 100%;
  width:300px;
  height: 450px;
`;

const Image = styled.img`
  object-fit: cover;
  object-position: center;
  height: 100%;
  width:100%;
`;

const TopBarContainer = styled.div`
  position: absolute;
  top: 0px;
  width: 300px;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  /*background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 100%);*/
  & img {
    width: 50px;
  }
`;

const IconConatainer = styled.div`
  cursor: pointer;
`;

const CardProduct = ({ item, cName }) => {
  const numberFormat = value =>
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'MXN',
    }).format(value);

  const [iconState, setIconState] = useState(false);
  const buttonHandler = () => {
    setIconState(current => !current);
  };

  return (
    <Container>
      <TopBarContainer>
        <img src={LogoFirma} alt="" />
        <IconConatainer onClick={buttonHandler}>
          {iconState ? <FavoriteIcon /> : <FavoriteBorderIcon />}
        </IconConatainer>
      </TopBarContainer>
      <Link to={`/product/Vestidos/${item.id}`}>
        <ImageContainer className='cursor-pointer'>
          <Image src={item?.image ? item.image:null} alt="" className="" />
        </ImageContainer>
      </Link>
      <div className="px-6 py-4 text-center">
        <h6 className="font-bold text-lg mb-2">{item?.title}</h6>
        <p className="text-gray-600 text-base">{numberFormat(item?.price)}</p>
      </div>
    </Container>
  );
};

export default CardProduct;
