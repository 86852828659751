export const MenuItems = [
  {
    title: 'Shop',
    path: '/ProductList/Women',
    subMenu: 0,
    cName: 'nav-links',
  },
  {
    title: 'Custom Made',
    path: '/Custom-Made',
    subMenu: 1,
    cName: 'nav-links',
  },
  {
    title: 'We Dress',
    path: '/We-Dress',
    subMenu: 1,
    cName: 'nav-links',
  },
  {
    title: 'Custom Made',
    path: '/Custom-Made',
    subMenu: 1,
    cName: 'nav-links',
  },
  {
    title: 'Nosotros',
    path: '/Nosotros',
    subMenu: 3,
    cName: 'nav-links',
  },
];


export const ShopMenu = [
  {
    id: 1,
    info: {
      title: 'SHOP ALL',
      desc: 'Explore all available items',
    },
    path: '/ProductList/shop-all',
    src: require('../../assets/img/Summer2022/Mens/Mis_Raices-Men-1.jpg'),
  },
  {
    id: 2,
    info: {
      title: 'MEN',
      desc: 'Discover our men’s collection',
    },
    path: '/ProductList/Men',
    src: require('../../assets/img/Summer2022/Mens/Mis_Raices-Men-2.jpg'),
  },
  {
    id: 3,
    info: {
      title: 'WOMEN',
      desc: 'Shop the latest women’s fashion',
    },
    path: '/ProductList/Women',
    src: require('../../assets/img/Summer2022/Mens/Mis_Raices-Men-3.jpg'),
  },
  {
    id: 4,
    info: {
      title: 'NO GENDER',
      desc: 'Gender-neutral styles',
    },
    path: '/ProductList/no-gender',
    src: require('../../assets/img/Summer2022/Mens/Mis_Raices-Men-4.jpg'),
  },
  {
    id: 5,
    info: {
      title: 'READY TO WEAR',
      desc: 'Fashionable ready-to-wear collection',
    },
    path: '/ProductList/ready-to-wear',
    src: require('../../assets/img/Summer2022/Mens/Mis_Raices-Men-1.jpg'),
    submenus: [
      {
        id: 51,
        info: { title: 'DRESSES', desc: 'Elegant dresses for every occasion' },
        path: '/ready-to-wear/dresses',
        src: require('../../assets/img/Summer2022/Mens/Mis_Raices-Men-2.jpg'),
      },
      {
        id: 52,
        info: { title: 'TOPS', desc: 'Trendy and stylish tops' },
        path: '/ready-to-wear/tops',
        src: require('../../assets/img/Summer2022/Mens/Mis_Raices-Men-3.jpg'),
      },
      {
        id: 53,
        info: { title: 'BOTTOMS', desc: 'Comfortable and stylish bottoms' },
        path: '/ready-to-wear/bottoms',
        src: require('../../assets/img/Summer2022/Mens/Mis_Raices-Men-4.jpg'),
      },
      {
        id: 54,
        info: { title: 'OUTWEAR', desc: 'Stay warm in style' },
        path: '/ready-to-wear/outwear',
        src: require('../../assets/img/Summer2022/Mens/Mis_Raices-Men-5.jpg'),
      },
      {
        id: 55,
        info: { title: 'TIE DYE', desc: 'Unique and colorful tie-dye fashion' },
        path: '/ready-to-wear/tie-dye',
        src: require('../../assets/img/Summer2022/Mens/Mis_Raices-Men-1.jpg'),
      },
    ],
  },
  {
    id: 6,
    info: {
      title: 'ACCESSORIES',
      desc: 'Complete your look with accessories',
    },
    path: '/accessories',
    src: require('../../assets/img/Summer2022/Mens/Mis_Raices-Men-5.jpg'),
    submenus: [
      {
        id: 61,
        info: { title: 'BAGS', desc: 'Stylish and practical bags' },
        path: '/accessories/bags',
        src: require('../../assets/img/Summer2022/Mens/Mis_Raices-Men-1.jpg'),
      },
      {
        id: 62,
        info: { title: 'SHOES', desc: 'Comfortable and trendy shoes' },
        path: '/accessories/shoes',
        src: require('../../assets/img/Summer2022/Mens/Mis_Raices-Men-2.jpg'),
      },
      {
        id: 63,
        info: { title: 'JEWELRY', desc: 'Elegant jewelry pieces' },
        path: '/accessories/jewelry',
        src: require('../../assets/img/Summer2022/Mens/Mis_Raices-Men-3.jpg'),
      },
      {
        id: 64,
        info: { title: 'HATS', desc: 'Stylish hats for any outfit' },
        path: '/accessories/hats',
        src: require('../../assets/img/Summer2022/Mens/Mis_Raices-Men-1.jpg'),
      },
      {
        id: 65,
        info: { title: 'SCARFS', desc: 'Fashionable and warm scarfs' },
        path: '/accessories/scarfs',
        src: require('../../assets/img/Summer2022/Mens/Mis_Raices-Men-1.jpg'),
      },
    ],
  },
  {
    id: 7,
    info: {
      title: 'LESS WASTE',
      desc: 'Sustainable and eco-friendly fashion',
    },
    path: '/less-waste',
    src: require('../../assets/img/Summer2022/Mens/Mis_Raices-Men-1.jpg'),
  },
  {
    id: 8,
    info: {
      title: 'CUSTOM MADE',
      desc: 'Personalized clothing, made just for you',
    },
    path: '/custom-made',
    src: require('../../assets/img/Summer2022/Mens/Mis_Raices-Men-1.jpg'),
  },
  {
    id: 9,
    info: {
      title: 'WE-DRESS',
      desc: 'Exclusive designs and collaborations',
    },
    path: '/we-dress',
    src: require('../../assets/img/Summer2022/Mens/Mis_Raices-Men-1.jpg'),
  },
];


